import React from 'react';
import './App.css';

function App() {
  // useState for name
  const [name, setName] = React.useState();
  const [text, setText] = React.useState();
  const [ergebis, setErgebnis] = React.useState();
  const [clicks, setClick] = React.useState(0);

  const rechneVolumenRechteck = (e) => {
    e.preventDefault();
    const laenge = e.target.length.value
    const breite = e.target.width.value
    const hoehe = e.target.height.value
    
    const volumen = laenge*breite*hoehe
    setErgebnis(volumen)
  }

  return (
    <div className="App">
      <header className="App-header">
      <img src="https://www.pngall.com/wp-content/uploads/2016/03/Moon-PNG.png" className="App-logo" alt="logo" />
        <h1>Volumenrechner</h1>

        Wie heisst du?
        <input type="text" placeholder="Gib mir deinen Namen!!!" name='name' onChange={(e) => setName(e.target.value)}/>
        <p>
          Hallo {name}! 
        </p>
        <p>
          Das Volumen eines Quaders ist: {ergebis}
        </p>
        <form onSubmit={rechneVolumenRechteck} className='volume-form'>
          <input type="text" placeholder="Gebe die Länge ein" name='length' />
          <input type="text" placeholder="Gebe die Breite ein"  name='width'/>
          <input type="text" placeholder="Gebe die Höhe ein" name='height' />
          <button type="submit">Berechnen</button>
        </form>


        <h2>Klick zähler</h2>
        <button onClick={() => setClick(clicks + 1)}>Click me</button>
        <p>
        {name} hat {clicks} mal geklickt!
        </p>

        <h2>Text umdrehen</h2>
        <input type="text" placeholder="Gib mir einen Text!!!" name='text' onChange={(e) => setText(e.target.value)}/>
        <button onClick={() => setText(text.split('').reverse().join(''))}>Text umdrehen</button>
        <p>
          {text}
        </p>
      </header>
    </div>
  );
}

export default App;
